exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-barbara-js": () => import("./../../../src/pages/Barbara.js" /* webpackChunkName: "component---src-pages-barbara-js" */),
  "component---src-pages-bev-js": () => import("./../../../src/pages/Bev.js" /* webpackChunkName: "component---src-pages-bev-js" */),
  "component---src-pages-community-jsx": () => import("./../../../src/pages/community.jsx" /* webpackChunkName: "component---src-pages-community-jsx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-darcy-js": () => import("./../../../src/pages/Darcy.js" /* webpackChunkName: "component---src-pages-darcy-js" */),
  "component---src-pages-ekuwah-js": () => import("./../../../src/pages/Ekuwah.js" /* webpackChunkName: "component---src-pages-ekuwah-js" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-glossary-jsx": () => import("./../../../src/pages/glossary.jsx" /* webpackChunkName: "component---src-pages-glossary-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-margaret-js": () => import("./../../../src/pages/Margaret.js" /* webpackChunkName: "component---src-pages-margaret-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-ron-js": () => import("./../../../src/pages/Ron.js" /* webpackChunkName: "component---src-pages-ron-js" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-speakers-js": () => import("./../../../src/pages/speakers.js" /* webpackChunkName: "component---src-pages-speakers-js" */),
  "component---src-pages-sponsorship-js": () => import("./../../../src/pages/sponsorship.js" /* webpackChunkName: "component---src-pages-sponsorship-js" */)
}

